/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '../src/app/fonts.scss';
@import '../src/app/admin/pages/reservations/reservations.page.scss';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// Styles for calendar

:root .calendar-page {
  --ion-color-light: #5C7554;
  --ion-color-light-rgb: 92, 117, 84;
  --ion-color-user-light-contrast: #f1f1f1;
  --ion-color-user-light-contrast-rgb: 255, 255, 255;
  --ion-color-user-light-shade: #5C7554;
  --ion-color-user-light-tint: #5C7554;
}

.ion-color-light {
  --ion-color-base: var(--ion-color-light);
  --ion-color-base-rgb: var(--ion-color-light-rgb);
  --ion-color-contrast: var(--ion-color-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-shade);
  --ion-color-tint: var(--ion-color-light-tint);
}

:root .toolbar-title-default {
  --ion-color-light: #F1F1F1;
  --ion-color-light-rgb: 241, 241, 241;
  --ion-color-user-light-contrast: #282828;
  --ion-color-user-light-contrast-rgb: 28, 28, 28;
  --ion-color-user-light-shade: #F1F1F1;
  --ion-color-user-light-tint: #F1F1F1;
}

.ion-color-light {
  --ion-color-base: var(--ion-color-light);
  --ion-color-base-rgb: var(--ion-color-light-rgb);
  --ion-color-contrast: var(--ion-color-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-shade);
  --ion-color-tint: var(--ion-color-light-tint);
}

.calendar-page::part(background) {
  background: #f1f1f1;
  padding-bottom: 15px !important;
}

.toolbar-title-default {
  --border-width: 0 !important;

  ion-title {
    font-family: "OpenSans-Regular";
    font-size: 14px;
    font-weight: 400;
    padding: 0 9px;
    text-align: start;
  }
}

ion-calendar-month {
  font-family: "OpenSans-Regular";
  color: #787586;

  .light {

    .days-box {

      .days {

        .days-btn {

          p {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .endSelection,
  .startSelection,
  .between {
    .days-btn p {
      font-size: 14px !important;
      color: #ffffff !important;
    }
  }

  .days .on-selected p {
    color: #ffffff !important;
  }
}

.week-toolbar {
  --background: #F1F1F1 !important;
  font-family: "OpenSans-SemiBold";
  color: #787586;
  --border-width: 0 !important;
}

// calendar modal on admin page

.calendar .week-toolbar {
  --background: transparent !important;
}

.month-title {
  text-align: start !important;
  font-size: 24px !important;
  font-family: "Raleway-Medium" !important;
  color: #282828 !important;
  padding: 0 21px;
  margin: 26px 0 0 !important;
}

// Modals style
ion-modal.auto-height {
  align-items: flex-end;
  --height: auto;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
  --border-radius: 20px 20px 0px 0px;

  &.notifications,
  &.rate-modal {

    &::part(backdrop) {
      background: url(./assets/booking_background.png) no-repeat center;
      background-size: cover;
      --backdrop-opacity: 1;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.6;
        background: #000000;
      }
    }
  }
}

ion-modal.fixed-height {
  align-items: flex-end;
  --height: 95%;
  --border-radius: 20px 20px 0px 0px;

  .ion-page {
    display: block;
    contain: content !important;
  }
}

ion-modal.web-push-modal,
ion-modal.download-from-store {
  --backdrop-opacity: 0 !important;
  --box-shadow: 0px 4px 30px rgba(40, 40, 40, 0.05) !important;
  --height: 212px;
  --border-radius: 6px;
  align-items: start;
}

ion-modal.download-from-store {
  --height: 221px;
}

ion-alert {
  .alert-button {
    color: #282828 !important;
  }
}

//global styles for texts

.title {
  margin: 0;
  font-family: "Raleway-Medium";
  font-size: 24px;
  color: #282828;
}

.subtitle {
  font-family: "Raleway-Medium";
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;

  &.reservations {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 160px;
    white-space: nowrap;
    color: #282828;
  }
}

.room-name {
  font-family: "Raleway-Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.room-address {
  font-family: "OpenSans-Regular";
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.description {
  margin: 0;
  font-family: "OpenSans-Regular";
  font-size: 14px;
  color: #2E2E2E;
}

.text {
  margin: 0;
  font-family: "OpenSans-Regular";
  font-size: 14px;
  line-height: 150%;

  &.day-name-reservations {
    margin-bottom: 8px;
  }

  &.day-num-reservations {
    font-size: 24px;
  }

  &.today {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #5C7554;
    color: #FAFAFA;
  }
}

.black-text {
  color: #282828;
}

.gray-text {
  color: #787586;
}

.green-text {
  color: #5C7554;
}

.white-text {
  color: #FFFFFF;

  &.white-room-name {
    font-family: "Raleway-Medium";
  }
}

//global styles for buttons

.btn {
  font-size: 14px;
  font-family: "OpenSans-SemiBold";
  text-transform: uppercase;
  padding: 15px;
  border-radius: 6px;
  width: 100%;

  &:disabled {
    background: #787586;
  }
}

.green-button {
  color: #FFFFFF;
  background: #5C7554;
}

.black-button {
  background: #282828;
  color: #ffffff;
}

.white-button {
  background: #ffffff;
  color: #282828;
  margin-right: 12px;
  border: 1px solid #282828;
}

.no-outline {
  border: 0;
  outline: none;
  text-decoration: none;
}

// styles for mat-select with search

.select-input-search {
  display: flex;
  z-index: 1;
  padding: 16px;
  background: #FFFFFF;
}

.mat-select-panel {
  overflow: hidden !important;
  max-height: initial !important;

  &.ng-animating {
    visibility: hidden;
  }
}

.cdk-overlay-pane {
  transform: translateX(-40px) translateY(-15px) !important;
}

.mat-select-panel-wrap .mat-select-panel {
  transform: translateX(-91px) !important;
  min-width: 295px !important;

  &.group-select {
    transform: translateX(23px) translateY(-20px) !important;
    min-width: calc(100% + 33px) !important;
    max-width: initial !important;
  }

  &.building-managers-panel {
    transform: translateX(-61px) !important;
  }
}

.custom-panel {
  height: 200px;
  max-height: 200px;
  overflow: auto;

  .mat-option {
    font-family: "OpenSans-Regular";
    font-size: 14px !important;
    line-height: 150% !important;
    color: #282828;
  }
}

.select-footer-btns {
  border-top: 1px solid #E2E2E2;
  display: flex;
  z-index: 1;
  padding: 16px;
  background: #FFFFFF;
}

ion-modal.custom-calendar {
  align-items: flex-end;
  --height: 83%;
  --border-radius: 20px 20px 0px 0px;

  &.admin {
    background: url(./assets/booking_background.png) no-repeat center;
    background-size: cover;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.6) !important;
  }

  &::before {
    content: "Select date";
    position: absolute;
    top: calc(17% - 60px);
    left: 21px;
    color: #ffffff;
    font-family: "Raleway-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    z-index: 10;
  }

  ion-header {

    ion-calendar-week {

      ion-toolbar {

        .week-title {
          display: flex;
          justify-content: space-between;
          padding: 0 21px !important;
          height: initial !important;

          li {
            width: initial !important;
          }
        }
      }
    }

    &:first-child>ion-toolbar {
      position: relative;
      padding-top: 0 !important;
      display: flex;
      align-items: center;
      height: 65px;
      padding: 0 12px;

      &::after {
        content: attr(date);
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        padding-right: 21px;
        color: #787586;
        font-family: "OpenSans-Regular";
        font-style: normal;
        font-size: 14px;
        line-height: 150%;
        z-index: 10;
      }

      ion-title {
        font-family: "OpenSans-Regular";
        font-size: 14px;
        line-height: 150%;
        width: fit-content !important;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 41px;
        padding: 0;
      }
    }
  }
}

//stripe cc styles

ngx-stripe-card {

  .StripeElement {
    background-color: #FAFAFA;
    padding: 13px 20px 13px 16px;
    margin-bottom: 8px;
    border-radius: 6px;
    border: 1px solid transparent;
    box-shadow: none;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    border: 1px solid #282828;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
}


//stripe ApplePay, GooglePay buttons

ngx-stripe-payment-request-button {

  .StripeElement {
    background-color: #000000;
    padding: 5px 20px 5px 16px;
    margin-bottom: 16px;
    border-radius: 6px;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
}

//admin panel global styles

.step-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #FAFAFA;
  border: 1px solid #E2E2E2;
  border-radius: 6px;
  max-height: 90vh;
  overflow: auto;

  .icon {
    min-height: 80px;
    width: 80px;
    border-radius: 50%;
    margin-bottom: 26px;
  }

  .buttons-wrapp {
    width: 100%;
    display: flex;
  }
}

app-room-creation {
  background: #f1f1f1;
  align-items: center;

  &.ion-page {
    overflow: auto;

    app-admin-header,
    app-progress-bar {
      width: 100%;
    }
  }
}

app-offices-list {

  &.ion-page {
    overflow: auto;

    app-admin-header {

      .header {
        margin-bottom: 0;
      }
    }
  }
}

app-server-err, app-not-found {
  &.ion-page {
    display: block;
  }
}

//styles for google dropdown

.pac-container {
  border: none !important;
}

//styles for toast

ion-toast {
  --color: #FEFEFE;
  --background: #282828;
  --border-radius: 6px;
  font-family: 'OpenSans-SemiBold';
  --max-width: 600px;

  &::part(button) {
    width: 60px;
    height: 60px;
    font-size: 30px;

    &:hover {
      cursor: text;
      background-color: transparent;
    }
  }

  &::part(message) {
    text-align: left;
    font-size: 14px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) {

  // Ion Alert styles

  ion-alert {

    .alert-radio-icon {
      border-color: #282828 !important;

      .alert-radio-inner {
        background-color: #282828;
      }
    }
  }

  ion-modal.custom-calendar {
    &::before {
      left: calc(50% - 297px);
    }
  }

  ion-modal.auto-height {
    &.notifications {
      ion-backdrop {
        &::before {
          left: calc(50% - 297px);
          top: calc(70% - 10px);
        }
      }
    }
  }
}

@media screen and (height: 812px) {
  ion-modal.auto-height {

    &.notifications {
      ion-backdrop {
        &::before {
          top: calc(70% - 60px);
        }
      }
    }

    &.rate-modal {
      ion-backdrop {
        &::before {
          top: calc(60% - 60px);
        }
      }
    }
  }
}

// FORMS CSS __START
.field-description {
  color: #787586;
  font-size: 12px;
  font-family: "OpenSans-Regular";
}

.invalid-input {
  border: 1px solid #E23C3C !important;
}

.erorr-message {
  color: #EB5757 !important;
}

// FORMS CSS __END

// Ion filter popover styles
.popover-wrapper {

  &::part(content) {
    width: 100%;
    max-width: 160px !important;
    transform-origin: left top !important;
  }

  ion-select-popover {
    ion-radio-group {
      ion-item {
        border-bottom: 1px solid #E2E2E2;
        --background-focused: transparent !important;
        --background: #FAFAFA !important;
        --background-hover: #FAFAFA !important;
      }

      ion-item:last-child {
        --border-color: rgba(0, 0, 0, 0);
        --background-focused: transparent;
        --background: #FAFAFA;
        --background-hover: #FAFAFA !important;
      }
    }

    ion-list {
      padding: 0;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    ion-item {
      --background: #FAFAFA;
      font-family: "OpenSans-Regular";
      font-size: 14px;
    }

    ion-radio {
      --color: transparent;
      --color-checked: transparent;
    }

    ion-backdrop {
      --backdrop-opacity: 0 !important;
    }
  }

  &.service-fee {
    &::part(content) {
      max-width: 248px !important;
    }
  }
}

//adaptive
.adaptive-wrapper {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}

body {
  background-color: #F1F1F1;
}

app-phone-confirmation-modal {
  background-color: #F1F1F1;
}

ion-infinite-scroll {
  max-height: 40px;
  overflow: hidden;

  ion-infinite-scroll-content {
    min-height: 1px;
    --color: #3880ff;
  }
}

// alert with checkboxes

.filter-alert {

  .alert-wrapper {
    min-width: 282px;

    .alert-head {
      padding: 0;
    }

    .alert-message {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      color: #282828;
      padding: 18px !important;
      font-family: "OpenSans-Regular";
      font-size: 14px;

      ion-checkbox {
        --border-color: #282828;
        --background-checked: #282828;
        --border-color-checked: #282828;
        margin-right: 14px;
      }
    }

    .alert-checkbox-group {

      button.select-interface-option {

        .alert-button-inner {

          .alert-checkbox-icon {
            width: 18px;
            height: 18px;
            left: 18px;
            border-color: #282828 !important;
            border-width: 2px;

            .alert-checkbox-inner {
              left: 4px;
              width: 6px;
              height: 12px;
            }
          }

          .alert-checkbox-label {
            padding-inline-start: 32px;
            font-family: "OpenSans-Regular";
            font-size: 14px;
          }
        }
      }

      button.select-interface-option[aria-checked="true"] {

        .alert-button-inner {

          .alert-checkbox-icon {
            background-color: #282828 !important;
          }
        }
      }
    }

    .alert-button-group {
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 16px;

      .alert-button {
        font-size: 14px;
        font-family: "OpenSans-SemiBold";
        text-transform: uppercase;
        padding: 15px;
        border-radius: 6px;
        width: 100%;
        margin: 0;

        &:first-child {
          margin-right: 12px;

          span {
            color: #ffffff;
            justify-content: center;
          }

          background: #282828;

          &:disabled {
            background: #787586;
          }
        }

        &:last-child {
          background: #ffffff;
          color: #282828;
          border: 1px solid #282828;

          span {
            color: #282828;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 955px) {

  .cdk-overlay-pane {
    top: 29vh !important;
  }

  .mat-select-panel-wrap .mat-select-panel {
    position: fixed !important;
    transform: translateX(-216px) !important;

    &.group-select {
      min-width: calc(100% + 32px) !important;
      transform: translateX(21px) translateY(14vh) !important;
    }

    &.building-managers-panel {
      transform: translateX(-138px) !important;
    }

    &.stats-panel {
      transform: translateX(25px) !important;
    }

    &.from-filter-panel {
      transform: translateX(25px) translateY(28px) !important;
      min-width: 330px !important;
    }
  }

  .subtitle {

    &.reservations {
      max-width: 30px;
      font-size: 10px;
    }
  }

  .text {

    &.black-text {
      &.day-name-reservations {
        margin-bottom: 4px;
        font-size: 12px;
        font-family: "Raleway-Medium";
      }
    }


    &.day-name-reservations {
      margin-bottom: 8px;
      font-size: 12px;
      font-family: "Raleway-Medium";
    }

    &.day-num-reservations {
      font-size: 12px;
    }

    &.today {
      width: 25px;
      height: 25px;
    }
  }
}

@media screen and (max-width: 955px) and (max-height: 736px) {
  .mat-select-panel-wrap .mat-select-panel {
    &.group-select {
      transform: translateX(21px) translateY(9vh) !important;
    }
  }
}

@media screen and (max-width: 955px) and (min-height: 896px) {
  .mat-select-panel-wrap .mat-select-panel {
    &.group-select {
      transform: translateX(21px) translateY(21vh) !important;
    }
  }
}

@media screen and (min-width: 375px) {

  .popover-wrapper {

    &.service-fee {

      &::part(content) {
        max-width: 160px !important;
      }
    }

    &.in-filter-modal {
      &::part(content) {
        max-width: 333px !important;
      }
    }
  }
}

@media screen and (min-width: 414px) {

  .popover-wrapper {

    &.service-fee {
      &::part(content) {
        max-width: 180px !important;
      }
    }

    &.in-filter-modal {
      &::part(content) {
        max-width: 372px !important;
      }
    }
  }
}

@media screen and (min-width: 428px) {

  .popover-wrapper {

    &.in-filter-modal {
      &::part(content) {
        max-width: 385px !important;
      }
    }
  }
}