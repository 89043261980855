@font-face {
  font-family: "OpenSans-Regular";
  src: url('../assets/fonts/OpenSans-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url('../assets/fonts/OpenSans-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url('../assets/fonts/OpenSans-Bold.ttf') format("truetype");
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url('../assets/fonts/OpenSans-ExtraBold.ttf') format("truetype");
}

@font-face {
  font-family: "Raleway-Medium";
  src: url('../assets/fonts/Raleway-Medium.ttf') format("truetype");
}

@font-face {
  font-family: "Raleway-Bold";
  src: url('../assets/fonts/Raleway-Bold.ttf') format("truetype");
}

@font-face {
  font-family: "Raleway-ExtraBold";
  src: url('../assets/fonts/Raleway-ExtraBold.ttf') format("truetype");
}