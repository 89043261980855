.reservations-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  flex-wrap: wrap;
  padding: 0px 40px 0 40px;
  box-sizing: border-box;

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;

    button {
      border-radius: 6px;
      font-family: "OpenSans-Regular";
      font-size: 14px;
    }

    .show-all-btn {
      min-width: 148px;
      padding: 14px 16px;
      margin-right: 16px;
      border-radius: 6px;
      border: 0px;
      font-size: 14px;
      font-family: "OpenSans-Regular";

      &::part(text) {
        padding-right: 8px;
      }

      &::part(icon) {
        opacity: 1;
        color: #ffffff;

        &:before {
          content: '';
          width: 12px;
          height: 7px;
          background: url(../../../../assets/icon/arrow-down-icon.svg) no-repeat center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          cursor: pointer;
        }
      }
    }
  }
}

body {
  position: relative;

  app-reservation-modal,
  app-reservation-list-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}

.main {
  padding: 0px 40px 10px;
  background: #f1f1f1;
  height: 100%;
  overflow: auto;
  position: relative;

  .content-wrapper {
    position: relative;
    padding: 104px 0 0;
    background: #FAFAFA;
    border-radius: 6px;
    box-sizing: border-box;
    height: 100%;
  }
}

// admin reservations calendar

full-calendar {
  height: 100%;

  .fc {
    background: #FAFAFA;
    height: 100%;
  }

  app-day-reservations {
    position: absolute;
    width: 100%;
    left: 0;
    padding-bottom: 10px;
  }

  // MONTH SELECTED

  // toolbar header
  .fc-toolbar.fc-header-toolbar {
    background: #F1F1F1;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 40px 0 16px;

    .fc-toolbar-chunk {

      .fc-button-primary:not(:disabled):active {
        background-color: transparent !important;
        color: #282828;
        border: none;
        box-shadow: none;
      }

      div {
        display: flex;
        align-items: center;

        &.fc-button-group {
          width: 239px;
          min-width: 239px;
          background: #ffffff;
          border-radius: 6px;
          padding: 4px;
        }

        .fc-toolbar-title {
          color: #282828;
          font-family: "OpenSans-Regular";
          font-size: 18px;
          margin-right: 10px;
        }

        button {
          background: transparent;
          border: none;
          outline: none;
          color: #282828;
          text-transform: capitalize;
          font-size: 14px;
          font-family: "OpenSans-Regular";
          padding: 10px 16px;
          border-radius: 4px;

          &.fc-next-button {
            padding: 0;
          }

          &.fc-button-active {
            background: #5C7554;
            color: #ffffff;
            box-shadow: none;
            border-radius: 4px;

            &:focus {
              box-shadow: none;
            }
          }

          span.fc-icon {
            &::before {
              color: #282828;
            }
          }

          &.fc-prev-button,
          &.fc-next-button {
            &:focus {
              box-shadow: none;
            }
          }

          &.fc-prev-button {
            margin-right: 10px;
            padding: 0;
          }
        }
      }
    }
  }

  // toolbar header end

  // rows ans cols week days
  .fc-view-harness {

    .fc-daygrid {

      table.fc-scrollgrid {

        tbody,
        thead {

          tr.fc-scrollgrid-section {

            th {

              .fc-scroller-harness {

                .fc-scroller {

                  table {

                    thead {

                      tr {

                        th {
                          padding: 26px 65px;
                          box-sizing: border-box;

                          .fc-scrollgrid-sync-inner {

                            a.fc-col-header-cell-cushion {
                              padding: 0;
                              font-family: "Raleway-Medium";
                              font-size: 16px;
                              color: #787586;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        // rows ans cols week days end

        // rows and cols days numbers
        tbody {

          .fc-scrollgrid-section {

            td {

              .fc-scroller-harness {

                .fc-scroller {

                  .fc-daygrid-body {

                    .fc-scrollgrid-sync-table {

                      tbody {

                        td {

                          .fc-daygrid-day-frame {

                            .fc-daygrid-day-top {

                              .fc-daygrid-day-number {
                                color: #282828;
                              }
                            }
                          }

                          &.fc-day-today {
                            background: #FAFAFA;

                            .fc-daygrid-day-frame {

                              .fc-daygrid-day-top {

                                .fc-daygrid-day-number {
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                  width: 26px;
                                  height: 26px;
                                  background: #5C7554;
                                  border-radius: 50%;
                                  color: #ffffff;
                                  text-align: center;
                                }
                              }
                            }
                          }

                          .fc-daygrid-day-frame {

                            .fc-daygrid-day-events {

                              .fc-daygrid-event-harness {

                                .fc-daygrid-event {
                                  border-left: 4px solid #5C7554 !important;
                                  min-height: 60px;
                                  padding: 5px 10px;
                                  background: #F1F1F1;
                                  font-size: 14px;
                                  margin: 0 0 2px 0;
                                }
                              }

                              .fc-daygrid-day-bottom {
                                text-align: center;
                                background: #fafafa;

                                .fc-daygrid-more-link {
                                  color: #282828;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // MONTH SELECTED END

  // WEEK SELECTED START

  .fc-timegrid {

    table.fc-scrollgrid {

      thead,
      tbody {

        tr.fc-scrollgrid-section {

          th {

            .fc-scroller-harness {

              .fc-scroller {

                table {

                  thead {

                    tr {

                      th {
                        padding: 5px 56px;
                        box-sizing: border-box;

                        .fc-scrollgrid-sync-inner {

                          .date-wrapper {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      tbody {

        .fc-scrollgrid-section {

          td {

            .fc-scroller-harness {

              .fc-scroller {

                .fc-timegrid-body {

                  .fc-timegrid-slots {

                    table {

                      tbody {

                        tr {

                          .fc-timegrid-slot {

                            .fc-timegrid-slot-label-frame {
                              text-align: center;
                            }

                            &.fc-timegrid-slot-minor {
                              display: none;
                            }

                            .fc-timegrid-slot-label-cushion {
                              padding-top: 20px;
                              font-size: 14px;
                              font-family: "OpenSans-Regular";
                              color: #787586;
                              text-transform: uppercase;
                            }
                          }
                        }
                      }
                    }
                  }

                  .fc-timegrid-cols {

                    .fc-timegrid-col {
                      background: #FAFAFA;

                      .fc-timegrid-col-frame {

                        &:hover {
                          z-index: 4 !important;
                        }

                        .fc-timegrid-col-events {
                          margin: 0;

                          .fc-timegrid-event-harness {

                            &:first-child {
                              width: 100%;
                            }
                          }

                          .fc-timegrid-more-link {
                            width: 100%;
                            background: #FAFAFA;
                            border-radius: 0;
                            top: initial !important;
                            box-shadow: none;
                            margin-bottom: 0;

                            .fc-timegrid-more-link-inner {
                              text-decoration: underline;
                              text-align: center;
                              border-left: 4px solid #5C7554;
                              border-right: 1px solid #5C7554;
                            }
                          }
                        }

                        .fc-timegrid-event-harness {
                          border: 1px solid #5C7554;
                          border-left: 4px solid #5C7554;
                          border-radius: 2px;
                          background: #F1F1F1;
                          z-index: 3 !important;

                          &:nth-child(1n) {
                            &:hover {
                              z-index: 6 !important;
                            }
                          }

                          &:nth-child(2n) {
                            &:hover {
                              z-index: 4 !important;
                            }
                          }

                          &:nth-child(3n) {
                            &:hover {
                              z-index: 5 !important;
                            }
                          }

                          .fc-timegrid-event {
                            width: 100%;
                            height: 100%;
                            cursor: pointer;
                            padding: 0 12px;
                            box-shadow: none;
                            position: relative;

                            .reservation-details-hover {
                              position: absolute;
                              top: -85px;
                              left: -10px;
                              width: 233px;
                              height: 70px;
                              background: #787586;
                              border-radius: 6px;
                              padding: 10px 16px;
                              display: flex;
                              align-items: center;
                              z-index: 3;

                              &::before {
                                content: '';
                                position: absolute;
                                bottom: -10px;
                                left: 10px;
                                border-style: solid;
                                border-width: 14px 8px 0 8px;
                                border-color: #787586 transparent transparent transparent;
                              }

                              &.full-day-bookings-left {
                                top: 150px;
                                left: -250px;

                                &:before {
                                  right: -10px;
                                  top: 45%;
                                  bottom: initial;
                                  left: initial;
                                  border-width: 7px 0 7px 16px;
                                  border-color: transparent transparent transparent #787586;
                                }
                              }

                              &.full-day-bookings-right {
                                top: 150px;
                                right: -255px;
                                left: initial;

                                &:before {
                                  top: 45%;
                                  bottom: initial;
                                  left: -10px;
                                  border-width: 7px 16px 7px 0;
                                  border-color: transparent #787586 transparent transparent;
                                }
                              }

                              &.earliest-bookings {
                                top: initial;
                                bottom: -85px;

                                &::before {
                                  top: -10px;
                                  bottom: initial;
                                  border-width: 0 8px 14px 8px;
                                  border-color: transparent transparent #787586 transparent;
                                }
                              }

                              &.saturday-bookings {
                                left: initial;
                                right: 20%;

                                &::before {
                                  left: initial;
                                  right: 10%;
                                }
                              }

                              .room-img-res-details {
                                min-width: 50px;
                                height: 50px;
                                margin-right: 16px;
                                border-radius: 6px;
                                background-size: cover;
                                background-position: center;
                                background-repeat: no-repeat;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.room-wrapp-reservations {
  cursor: pointer;

  .room-description {

    .event-content {
      max-width: 150px;

      .description {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .room-img-reservations {
    display: none;
  }

  .details {
    display: none;
  }
}

.fc-popover {
  visibility: hidden;
}

@media screen and (max-width: 955px) {

  .main {
    padding: 0 21px 10px;
    overflow-x: hidden;

    .content-wrapper {
      padding-top: 158px;
    }
  }

  full-calendar {

    .fc-toolbar.fc-header-toolbar {
      padding: 0;
      flex-wrap: wrap;

      .fc-toolbar-chunk {
        margin-bottom: 16px;

        &:nth-child(2) {
          margin-bottom: 26px;
        }

        &:first-child,
        &:last-child {
          width: 100%;
        }

        &:last-child {
          div {
            justify-content: center;
          }
        }
      }
    }

    // rows ans cols week days mobile

    .fc-view-harness {

      .fc-daygrid {

        table.fc-scrollgrid {

          tbody,
          thead {

            tr.fc-scrollgrid-section {

              th {

                .fc-scroller-harness {

                  .fc-scroller {

                    table {

                      thead {

                        tr {

                          th {
                            padding: 15px 0;
                            box-sizing: border-box;

                            .fc-scrollgrid-sync-inner {

                              a.fc-col-header-cell-cushion {
                                padding: 0;
                                font-family: "Raleway-Medium";
                                font-size: 12px;
                                color: #787586;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          tbody {

            .fc-scrollgrid-section {

              td {

                .fc-scroller-harness {

                  .fc-scroller {

                    .fc-daygrid-body {

                      .fc-scrollgrid-sync-table {

                        tbody {

                          td {

                            .fc-daygrid-day-frame {

                              .fc-daygrid-day-top {

                                .fc-daygrid-day-number {
                                  font-size: 12px;
                                }
                              }
                            }

                            &.fc-day-today {

                              .fc-daygrid-day-frame {

                                .fc-daygrid-day-top {

                                  .fc-daygrid-day-number {
                                    width: 19px;
                                    height: 19px;
                                  }
                                }
                              }
                            }

                            .fc-daygrid-day-frame {

                              .fc-daygrid-day-events {

                                .fc-daygrid-event-harness {

                                  .fc-daygrid-event {
                                    min-height: initial;
                                    padding: 6px;

                                    &:focus {
                                      box-shadow: none;
                                      outline: none;

                                      &:after {
                                        background: initial;
                                      }
                                    }
                                  }
                                }

                                .fc-daygrid-day-bottom {

                                  .fc-daygrid-more-link {
                                    font-size: 10px;
                                    text-decoration: underline;
                                    max-width: 39px;
                                    display: inline-block;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    outline: none;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // WEEK SELECTED START

    .fc-timegrid {

      table.fc-scrollgrid {

        thead,
        tbody {

          tr.fc-scrollgrid-section {

            th {

              .fc-scroller-harness {

                .fc-scroller {

                  table {

                    thead {

                      tr {

                        th {
                          padding: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        tbody {

          .fc-scrollgrid-section {

            td {

              .fc-scroller-harness {

                .fc-scroller {

                  .fc-timegrid-body {

                    .fc-timegrid-slots {

                      table {

                        tbody {

                          tr {

                            .fc-timegrid-slot {

                              .fc-timegrid-slot-label-cushion {
                                padding: 26px 5px 40px 5px;
                                font-size: 14px;
                                font-family: "OpenSans-Regular";
                                color: #787586;
                              }
                            }
                          }
                        }
                      }
                    }

                    .fc-timegrid-cols {

                      .fc-timegrid-col {

                        .fc-timegrid-col-frame {

                          .fc-timegrid-col-events {

                            .fc-timegrid-more-link {

                              .fc-timegrid-more-link-inner {
                                font-size: 10px;
                                text-decoration: underline;
                                max-width: 40px;
                                display: inline-block;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                border: none;
                                outline: none;
                              }
                            }
                          }

                          .fc-timegrid-event-harness {

                            .fc-timegrid-event {
                              padding: 0;

                              &::before,
                              &::after {
                                content: none
                              }

                              &:focus {
                                outline: none;
                                overflow: hidden;

                                &:after {
                                  background: initial;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .room-wrapp-reservations {
    .room-description {

      .event-content {

        .description {
          font-size: 10px;
          max-width: 30px;
        }
      }
    }
  }
}